import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { LOGIN } from "@/lib/graphql";
import { useApolloClient, useMutation } from "@apollo/client";
import { GoogleLogo, Spinner } from "@phosphor-icons/react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "sonner";
import { LoginParams } from "../../types/auth";

type Props = {
  onForgotPassword: () => void;
  onRegister: () => void;
  onClose: () => void;
};

const Login = ({ onForgotPassword, onRegister, onClose }: Props) => {
  const { handleSubmit, control } = useForm<LoginParams>();
  const client = useApolloClient();
  const [doLogin, { loading }] = useMutation<
    { response: { data: any } },
    {
      params: LoginParams;
    }
  >(LOGIN, {
    onCompleted: (data) => {
      localStorage.setItem("token", data.response.data.accessToken);
      toast.success("Đăng nhập thành công!");
      client.refetchQueries({ include: ["getMe"] });
      onClose();
    },
    onError: () => {
      toast.error("Đăng nhập thất bại!");
    },
    notifyOnNetworkStatusChange: true,
  });

  const onSubmit = async (data: LoginParams) => {
    doLogin({
      variables: {
        params: data,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <>
        <Button
          type="button"
          className="w-full flex items-center"
          variant="outline"
          onClick={() =>
            (window.location.href =
              "http://api.clone-business.com/auth/google/callback")
          }
        >
          <GoogleLogo className="text-xl me-2" weight="fill" /> Đăng Nhập với
          Google
        </Button>

        <div className="py-6 flex items-center text-sm before:flex-1 before:border-t-[0.5px] before:border-gray-100 before:me-6 after:flex-1 after:border-t-[0.5px] after:border-gray-100 after:ms-6">
          Hoặc tiếp tục với
        </div>

        <Controller
          control={control}
          name="username"
          rules={{ required: "Tên đăng nhập không được để trống" }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <Label htmlFor="username">Tên Đăng Nhập</Label>
                <Input
                  id="username"
                  onChange={onChange}
                  value={value}
                  type="text"
                  placeholder="Tên đăng nhập"
                />
              </>
            );
          }}
        />
        <Controller
          control={control}
          name="password"
          rules={{ required: "Mật khẩu không được để trống" }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <Label htmlFor="password">Mật khẩu</Label>
                <Input
                  id="password"
                  onChange={onChange}
                  value={value}
                  type="password"
                  placeholder="Mật khẩu"
                />
              </>
            );
          }}
        />
      </>
      <div
        className="text-right text-primary-content mt-2 cursor-pointer font-medium"
        onClick={onForgotPassword}
      >
        Quên Mật Khẩu?
      </div>
      <Button
        disabled={loading}
        type="submit"
        className="mt-4 w-full"
        variant="primary"
        onClick={handleSubmit(onSubmit)}
      >
        {loading ? (
          <Spinner
            className="animate-spin text-neutural-strong text-center"
            size={24}
          />
        ) : (
          "Đăng Nhập"
        )}
      </Button>
      <div className="mt-4 text-center">
        <span className="font-medium">Bạn chưa có tài khoản?</span>
        <span
          className="text-primary-content cursor-pointer font-medium conv__signin_or_signup"
          onClick={onRegister}
        >
          {" "}
          Đăng Ký Ngay
        </span>
      </div>
    </form>
  );
};

export default Login;
